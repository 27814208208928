import React, {useState} from 'react';
import './header.css'
import { ReactComponent as MenuIcon } from '../images/icons/menu.svg'
import { ReactComponent as CloseMenu } from '../images/icons/x.svg'
import { Link } from 'react-router-dom';

const Header = ({retailer}) => {

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return <div className="header-nav">
    <div className="logo-nav">

      <ul className={click ? "nav-options active" : "nav-options"}>
        <li className={!retailer ? "option selected-nav" : "option"} onClick={closeMobileMenu}>
            {/* <HouseIcon className="house-icon" /> */}
          <Link to="/" >Reload/Payment</Link>
        </li>
        <li className={retailer ? "option selected-nav" : "option"} onClick={closeMobileMenu}>
          <Link to="/retailer">Retailer</Link>
        </li>
      </ul>
    </div>
    <div className="mobile-menu">
      {click ? (
        <CloseMenu onClick={handleClick} className="menu-icon" />
      ) : (
        <MenuIcon onClick={handleClick} className="menu-icon" />
      )}
    </div>
  </div>
}

export default Header;