import React, { useState, useEffect } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import back_arrow from '../images/back_arrow.png';
import config from "../config.json";

config.image_url = process.env.REACT_APP_IMAGE_URL;

const PaymentOptions = ({ display, onBtnBack, onPay, paymentMethods, recaptchaEnableDisable, reCaptchaToken }) => {
    
    const [selectedMethod, setSelectedMethod] = useState({payment_id: '-1'});
    const [reCaptchaFECompleted, setReCaptchaFECompleted] = useState(true);
    const [reCaptchaEnable, setReCaptchaEnable] = useState(true);
    console.log(reCaptchaFECompleted);
    console.log(selectedMethod);

    const pay = () => {
        if (selectedMethod.payment_id !== '-1' && (reCaptchaFECompleted || !reCaptchaEnable)) {
            switch (selectedMethod.payment_type) {
                case '0':
                    onPay(false);
                    break;
                case '1':
                    onPay(true);
                    break;
                default:
                    break;
            }
        }
        
    }

    useEffect(() => {
        setSelectedMethod({payment_id: '-1'});
        setReCaptchaFECompleted(false);
    }, [paymentMethods])
    
    useEffect(() => {
        // Update reCaptchaCompleted based on recaptchaEnableDisable
        if (recaptchaEnableDisable === 0) {
            setReCaptchaEnable(false);
        }
    }, [recaptchaEnableDisable]);

    const handleReCaptchaChange = async (value) => {
        try {
            setReCaptchaFECompleted(true);
            reCaptchaToken(value);
        } catch (e) {
        }
    }

    return (
        <div className={display} style={{ paddingBottom: "20px" }}>
            <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-12">
                    <button className="summary-back-button" style={{ fontSize: "20px", fontWeight: "450", color: "#666", cursor: "pointer" }} onClick={() => { onBtnBack("payment") }}>
                        <img src={back_arrow} alt="Go Back" style={{ marginLeft: "0px" }} width="25" />
                        <span>Back</span>
                    </button>
                </div>
                <div className="col-12 summary-container">
                    <label className="sum-title">Select payment method</label>
                </div>
                <div className="col-12">
                    {paymentMethods.map(item => (
                        <div key={item.payment_id} className="payment-option-container">
                            <div
                                onClick={() => { 
                                    setSelectedMethod(item);
                                    setReCaptchaFECompleted(false);
                                }}
                                className={selectedMethod.payment_id === item.payment_id ? "payment-option selected" : "payment-option"}
                                style={
                                    selectedMethod.payment_id === item.payment_id ?
                                        { ...styles.borderSelected, ...styles.paymentOption } :
                                        { ...styles.borderNotSelected, ...styles.paymentOption }
                                }
                            >
                                <img
                                    alt={'payment option'}
                                    style={styles.image}
                                    src={config.image_url + item.image}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="col-12" style={{ marginTop: "50px" }}>
                    {selectedMethod.payment_id !== '-1' && !reCaptchaFECompleted && reCaptchaEnable &&(
                        <ReCAPTCHA
                            sitekey="6LdKRc8pAAAAAIhIeUu9CY2F_Gn2qGj6oWacsHCw"
                            onChange={handleReCaptchaChange}
                            theme="light"
                            type="image"
                        />
                    )}
                    {/* {selectedMethod.payment_id !== '-1' && reCaptchaFECompleted &&(
                        <p style={{ fontWeight: 'bold', fontSize: '1.2em', color: 'green' }}>Verification is in Progress! Do not close the Page.</p>
                    )} */}
                    {selectedMethod.payment_id !== '-1' && (reCaptchaFECompleted || !reCaptchaEnable) && (
                        <button
                            id="paynow-btn"
                            className="airtel-button"
                            onClick={pay}
                        >Pay Now</button>
                    )}
                </div>
            </div>
        </div>
    );
}

const styles = {
    paymentOption: {
        margin: 10,
        width: 90,
        height: 90,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        borderStyle: 'solid',
        cursor: 'pointer'
    },
    borderNotSelected: {
        borderColor: '#e0e0e0',
        borderWidth: 1,
    },
    borderSelected: {
        borderColor: 'red',
        borderWidth: 2
    },
    image: {
        width: 70,
        height: 70,
        margin: 10
    }
}

export default PaymentOptions;